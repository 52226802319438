

import {Component, Mixins, Ref, Watch} from "vue-property-decorator";
import GenelIslemFormWizard, {GenelIslemEntity} from "@/components/forms/form-wizard/eicrapro/GenelIslemFormWizard.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {UyapHacizEvrakTuru} from "@/plugins/uyap-plugin/enum/uyap/evrak/UyapHacizEvrakTuru";
import {AvukatTalepListCevapRow} from "@/plugins/uyap-plugin/uyap/AvukatTalepList";
import {ITask, Task} from "@/plugins/uyap-plugin/store/modules/GorevYoneticisiTypes";
import {TaskTuru} from "@/plugins/uyap-plugin/enum/TaskTuru";
import {TaskDurum} from "@/plugins/uyap-plugin/enum/TaskDurum";
import {VForm} from "@/types";
import AvukatTalepPicker from "@/components/pickers/uyap/AvukatTalepPicker.vue";
import PersonelPicker from "@/components/pickers/PersonelPicker.vue";
import {UyapDigerEvrakTuru} from "@/plugins/uyap-plugin/enum/uyap/evrak/UyapDigerEvrakTuru";
import AcizBelgesiHazirlanmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/AcizBelgesiHazirlanmasiTalepEvrakForm.vue";
import AracUzerindekiHacizSerhininKaldirilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/AracUzerindekiHacizSerhininKaldirilmasiTalepEvrakForm.vue";
import AracUzerindekiYakalamaSerhininKaldirilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/AracUzerindekiYakalamaSerhininKaldirilmasiTalepEvrakForm.vue";
import BankadanHacizliParaninIstenilmesiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/BankadanHacizliParaninIstenilmesiTalepEvrakForm.vue";
import BorcluTarafindanYapilanOdemelerinHesabaAktarilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/BorcluTarafindanYapilanOdemelerinHesabaAktarilmasiTalepEvrakForm.vue";
import DosyadaHacizliMalinSatisiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/DosyadaHacizliMalinSatisiTalepEvrakForm.vue";
import DosyadakiAvansinIadesiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/DosyadakiAvansinIadesiTalepEvrakForm.vue";
import DosyadakiHacizlerinKaldirilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/DosyadakiHacizlerinKaldirilmasiTalepEvrakForm.vue";
import DosyadakiIbanBilgisininGuncellenmesiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/DosyadakiIbanBilgisininGuncellenmesiTalepEvrakForm.vue";
import DosyaninYetkiliIcraDairesineGonderilmesiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/DosyaninYetkiliIcraDairesineGonderilmesiTalepEvrakForm.vue";
import HacizliMalinKiymetTakdiriIcinTalimatYazilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/HacizliMalinKiymetTakdiriIcinTalimatYazilmasiTalepEvrakForm.vue";
import HacizliMalinSatisiIcinTalimatYazilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/HacizliMalinSatisiIcinTalimatYazilmasiTalepEvrakForm.vue";
import HacizliTasinirMallarinMuhafazaAltinaAlinmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/HacizliTasinirMallarinMuhafazaAltinaAlinmasiTalepEvrakForm.vue";
import HaricenTahsilatBildirimiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/HaricenTahsilatBildirimiTalepEvrakForm.vue";
import Iik121MaddeyeGoreYetkiVerilmesiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/Iik121MaddeyeGoreYetkiVerilmesiTalepEvrakForm.vue";
import Iik150cSerhiEklenmesiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/Iik150cSerhiEklenmesiTalepEvrakForm.vue";
import KiymetTakdirininYapilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/KiymetTakdirininYapilmasiTalepEvrakForm.vue";
import MaasUzerindekiHacizlerinKaldirilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/MaasUzerindekiHacizlerinKaldirilmasiTalepEvrakForm.vue";
import MuhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/MuhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligiTalepEvrakForm.vue";
import RehinAcigiBelgesiHazirlanmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/RehinAcigiBelgesiHazirlanmasiTalepEvrakForm.vue";
import RehininParayaCevrilmesiSerhiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/RehininParayaCevrilmesiSerhiTalepEvrakForm.vue";
import TasinmazHaczininKaldirilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/TasinmazHaczininKaldirilmasiTalepEvrakForm.vue";
import YuzuncuMaddeyeYararBilgiIstenilmesiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/YuzuncuMaddeyeYararBilgiIstenilmesiTalepEvrakForm.vue";
import {
  TopluHacizHazirlaTaskHandler
} from "@/plugins/uyap-plugin/store/modules/TaskHandler/TopluHacizHazirlaTaskHandler";
import {ProgramPaths} from "@/enum/ProgramPaths";
import {VarsayilanGenel} from "@/classes/uyapTalep/VarsayilanGenel";
import NewFormDialog from "@/components/dialogs/NewFormDialog.vue";
import NewFormWrapper from "@/components/NewFormWrapper.vue";
import DosyaninIslemdenKaldirilmasiTalepEvrakForm
  from "@/components/forms/uyap-evrak/diger-evrak/DosyaninIslemdenKaldirilmasiTalepEvrakForm.vue";
import TopluTebligatDuzenleForm from "@/components/eicrapro/TopluTebligatDuzenleForm.vue";
import TopluGenelTalepDuzenleForm from "@/components/eicrapro/TopluGenelTalepDuzenleForm.vue";

@Component({
  components: {
    TopluGenelTalepDuzenleForm,
    TopluTebligatDuzenleForm,
    DosyaninIslemdenKaldirilmasiTalepEvrakForm,
    NewFormWrapper,
    NewFormDialog,
    YuzuncuMaddeyeYararBilgiIstenilmesiTalepEvrakForm,
    TasinmazHaczininKaldirilmasiTalepEvrakForm,
    RehininParayaCevrilmesiSerhiTalepEvrakForm,
    RehinAcigiBelgesiHazirlanmasiTalepEvrakForm,
    MuhafazaAltindakiMallarinHacizBakiKalarakYeddieminDegisikligiTalepEvrakForm,
    MaasUzerindekiHacizlerinKaldirilmasiTalepEvrakForm,
    KiymetTakdirininYapilmasiTalepEvrakForm,
    Iik150cSerhiEklenmesiTalepEvrakForm,
    Iik121MaddeyeGoreYetkiVerilmesiTalepEvrakForm,
    HaricenTahsilatBildirimiTalepEvrakForm,
    HacizliTasinirMallarinMuhafazaAltinaAlinmasiTalepEvrakForm,
    HacizliMalinSatisiIcinTalimatYazilmasiTalepEvrakForm,
    HacizliMalinKiymetTakdiriIcinTalimatYazilmasiTalepEvrakForm,
    DosyaninYetkiliIcraDairesineGonderilmesiTalepEvrakForm,
    DosyadakiIbanBilgisininGuncellenmesiTalepEvrakForm,
    DosyadakiHacizlerinKaldirilmasiTalepEvrakForm,
    DosyadakiAvansinIadesiTalepEvrakForm,
    DosyadaHacizliMalinSatisiTalepEvrakForm,
    BorcluTarafindanYapilanOdemelerinHesabaAktarilmasiTalepEvrakForm,
    BankadanHacizliParaninIstenilmesiTalepEvrakForm,
    AracUzerindekiYakalamaSerhininKaldirilmasiTalepEvrakForm,
    AracUzerindekiHacizSerhininKaldirilmasiTalepEvrakForm,
    AcizBelgesiHazirlanmasiTalepEvrakForm,
    PersonelPicker,
    AvukatTalepPicker,
    GenelIslemFormWizard
  }
})
export default class TopluGenelTalepHazirlaFormWizard extends Mixins(SimpleInputMixin) {
  @Ref('form') readonly form!: VForm;
  @Ref('genelIslemForm') genelIslemForm!: GenelIslemFormWizard;

  localValue: GenelIslemEntity = new GenelIslemEntity();
  taskDurum = TaskDurum;
  topluGenelDurum = TopluGenelDurum;
  durum: TopluGenelDurum = this.topluGenelDurum.bosta;
  genelEvrakTuru = UyapDigerEvrakTuru;
  valid = false;
  onFly = false;
  hideNext = false;
  talepler: Array<AvukatTalepListCevapRow> = [];
  message = "";
  varsayilanTalepler = new VarsayilanGenel();

  stepperHeaders = [
    "Listelenecek Takipler",
    "Takipleri Seç",
    "Genel Talepleri Hazırla",
    "Genel Talepleri Gönder",
    "İşlemler Sıraya Alındı"
  ];
  secilenHeaders = [
    {text: "Birim Adı", value: "birimAdi"},
    {text: "Dosya Esas No", value: "dosyaNo"},
    {text: "Açılış Tarihi", value: "dosyaAcilisTarihi"},
    {text: "Dosya Durumu", value: "dosyaDurumAciklama"}
    /*{text: "Durum", value: "status", align: 'center'}*/
  ];
  talepDuzenleHeaders = [
    {text: "", value: "select", sortable: false, width: "50"},
    {text: "Dosya", value: "dosya"},
    {text: "Borçlu", value: "borclu"},
    {text: "İşlemler", value: "actions", width: "50", sortable: false}
  ];

  @Watch('localValue.secilen_takipler')
  async onSecilenTakiplerChange() {
    let secilenTakip: any;
    for (secilenTakip of this.localValue.secilen_takipler) {
      if (!secilenTakip.task) {
        secilenTakip.task = new Task();
        secilenTakip.task.dosya_esas_no = secilenTakip.dosyaNo;
        secilenTakip.task.birim_adi = secilenTakip.birimAdi;
        secilenTakip.task.task_type_id = TaskTuru.toplu_haciz_hazirla;
        secilenTakip.task.dosya_id = secilenTakip.dosyaId;
        this.input();
      }
    }
  }

  onStepChange(stepNo: number) {
    if (stepNo == 3)
      this.hideNext = true;
    else
      this.hideNext = false;
  }

  onTalepChange() {
    this.localValue.secilen_takipler.forEach(takip => {
      if (takip.task && (takip.task.status == this.taskDurum.BITTI_BASARILI || takip.task.status == this.taskDurum.BITTI_HATALI))
        takip.task.status = this.taskDurum.SIRADA;
    })
    this.input();
  }

  setVarsayilanTalep(talepKod: number) {
    let talep = this.talepler.find(talepItem => talepItem.talepKodu == talepKod) as AvukatTalepListCevapRow;
    for (let takip of this.localValue.secilen_takipler) {
      for (let borclu of takip.task.response.borclular) {
        borclu = this.varsayilanTalepler.setVarsayilanTalepler(borclu, [talep]);
      }
    }
  }

  async createGenelTalepHazirlaTask() {
    if (this.form.validate()) {
      this.onFly = true;
      this.durum = this.topluGenelDurum.hazirlaniyor;
      this.message = "Talepler hazırlanıyor..."
      for (let [index, takip] of Object.entries(this.localValue.secilen_takipler)) {
        // @ts-ignore
        let task: ITask = takip.task;
        if (!task.status) {
          task.status = TaskDurum.SIRADA;
        }
        try {
          this.message = (Number(index) + 1) + ". dosyanın talepleri hazırlanıyor.";
          if (task.status == TaskDurum.SIRADA) {
            task.data = {hacizParams: {}, digerTalepParams: this.talepler};
            let handler = new TopluHacizHazirlaTaskHandler();
            let taskResult = await handler.handle(task);
            task = taskResult;
            task.status = this.taskDurum.BITTI_BASARILI;
          }
          this.message = Number(index + 1) + ". dosyanın varsayılan talepleri uygulanıyor."
          for (let borclu of task.response.borclular) {
            borclu.selected = true;
            borclu = this.varsayilanTalepler.setVarsayilanTalepler(borclu, this.talepler);
          }
          this.message = Number(index + 1) + ". dosyanın talepleri hazır."
        } catch (err) {
          task.status = this.taskDurum.BITTI_HATALI;
          this.message = Number(index + 1) + ". dosyanın talepleri hazırlanırken hata oluştu. (" + err + ")"
          task.description = err;
        } finally {
          this.input();
        }
      }
      this.durum = this.topluGenelDurum.hazir;
      this.genelIslemForm.nextStep();
      this.onFly = false;
    }
  }

  showTalep(talepKod: UyapDigerEvrakTuru): boolean {
    return !!this.talepler.find((talep: AvukatTalepListCevapRow) => (talep.talepKodu == talepKod));
  }

  async createTask() {
    this.onFly = true;
    let borcluSecildiMi = false;
    let tasks: Array<Task> = [];
    let takip: any;
    for (takip of this.localValue.secilen_takipler) {
      for (let borclu of takip.task.response.borclular) {
        if (borclu.selected) {
          let task: Task = new Task();
          task.dosya_esas_no = takip.dosyaNo;
          task.birim_adi = takip.birimAdi;
          task.dosya_id = takip.dosyaId;
          task.task_type_id = TaskTuru.toplu_genel_talep_hazirla;
          task.data = {
            dosya: takip.task.response.dosya,
            params: takip.task.response.params,
            borclu: borclu
          };
          tasks.push(task);
        }
      }
    }
    if (borcluSecildiMi) {
      await this.$store.dispatch('postTasks', tasks);
      await this.$router.push(ProgramPaths.eicrapro + '/islemler');
      this.genelIslemForm.activeStep++;
    } else
      this.$toast.warning('Talep gönderilecek borçlu seçiniz.')
    this.onFly = false;
  }
}

enum TopluGenelDurum {
  bosta = 1,
  hazirlaniyor = 2,
  hazir = 3
}
